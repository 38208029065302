import { Header } from './styles/Header';
import { H1, H2, H3, Link, P } from './styles/Headings';
import { Main } from './styles/Main';
import { NavBar } from './styles/Navbar';
import { gigs } from './data/gigs';
import { ListItem } from './styles/ListItem';
import spotifyLogo from './icons/Spotify_Logo.png';
import instagramLogo from './icons/instagram.png';
import facebookLogo from './icons/facebook.png';
import trallskruvLogoWhite from './icons/LogoWhiteTrans.png';
import praper from './img/praper.jpeg';
import farbror from './img/farbror.jpg';
import empacoolboy from './img/empacoolboy_90.jpg';
import drangen from './img/drangen.jpg';
import svensson from './img/svensson.jpg';
import arrow from './icons/arrow.svg';

import styled from 'styled-components';

export const Icons = styled.div`
  display: flex;
  position: fixed;
  top: 8px;
  right: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ArrowIcon = styled.img`
  filter: invert(1);
  transform: rotate(180deg);
  opacity: 0.7;
  transition: 300ms;
  &:hover {
    opacity: 0.9;
  }
`;

const PictureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;

  @media (max-width: 520px) {
    grid-template-columns: repeat(3, 1fr);
    img {
      &:nth-child(4) {
        grid-column: 1/3;
        grid-row: 2;
      }
      &:nth-child(5) {
        grid-column: 2/4;
        grid-row: 2;
      }
    }
  }
`;

const Slogan = styled.p`
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.7);
`;

function App() {
  return (
    <div className="App" id="top">
      <Header>
        <Icons>
          <Link
            href="https://open.spotify.com/artist/4XIg7phBxKa77OOkkdvGGg"
            target="_blank"
          >
            <img src={spotifyLogo} height="48" alt="instagram link" />
          </Link>
          <Link
            href="https://www.instagram.com/trallskruvpunk/"
            target="_blank"
          >
            <img
              src={instagramLogo}
              height="48"
              alt="instagram link"
            />
          </Link>
          <Link
            href="https://www.facebook.com/trallskruvpunk/"
            target="_blank"
          >
            <img
              src={facebookLogo}
              height="48"
              alt="instagram link"
            />
          </Link>
          {/* <Link
            href="https://trallskruv.bandcamp.com/"
            target="_blank"
          >
            <img
              src="/icons/bandcamp.png"
              height="48"
              alt="instagram link"
            />
          </Link> */}
        </Icons>
        <img
          src={trallskruvLogoWhite}
          alt="Logotype"
          style={{ position: 'fixed', top: 8, left: 8, height: 100 }}
        />

        <H1>Trallskruv</H1>
        <NavBar>
          <Link href="#gigs">Gigs</Link>
          <Link href="mailto:trallskruvpunk@gmail.com?subject=Bokningförfrågan">
            Boka
          </Link>
        </NavBar>
      </Header>
      <Main>
        <H2 id="gigs">Gigs</H2>
        <ul style={{ width: '100%' }}>
          {gigs.map((gig, i) => (
            <ListItem key={i}>
              {gig.link ? (
                <Link
                  href={gig.link}
                  target="_blank"
                  style={{ paddingBottom: 0 }}
                >
                  <H3>{gig.place}</H3>
                </Link>
              ) : (
                <H3>{gig.place}</H3>
              )}
              <P>{gig.city}</P>
              <P>{gig.date}</P>
            </ListItem>
          ))}
        </ul>
      </Main>
      <footer>
        <PictureContainer>
          <img src={praper} alt="Logotype" height="100" />
          <img src={farbror} alt="Logotype" height="100" />
          <img src={empacoolboy} alt="Logotype" height="100" />
          <img src={svensson} alt="Logotype" height="100" />
          <img src={drangen} alt="Logotype" height="100" />
        </PictureContainer>
        <ArrowContainer>
          <Link href="#top">
            <ArrowIcon src={arrow} alt="arrow" width="50" />
          </Link>
        </ArrowContainer>
        <Slogan>Sveriges snabbaste dansband</Slogan>
      </footer>
    </div>
  );
}

export default App;
