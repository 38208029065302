import styled from 'styled-components';

export const ListItem = styled.li`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  align-items: center;
  margin-top: 2rem;
  text-align: left;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }
  h2 {
    text-align: left;
  }
`;
