export const gigs = [
  {
    place: 'Magasinet',
    city: 'Nyköping',
    link: 'https://www.facebook.com/events/1892701807859222?locale=sv_SE',
    bands: ['Varnagel', 'Flaskhals'],
    date: '2024-10-05',
  },
  {
    place: 'House of punk',
    city: 'Borlänge',
    link: 'https://www.facebook.com/events/468069659379029?locale=sv_SE',
    bands: [],
    date: '2024-10-11',
  },
  {
    place: 'Umapunk',
    city: 'Umeå',
    link: 'https://www.facebook.com/events/1047152956779073?locale=sv_SE',
    bands: [],
    date: '2024-10-25',
  },
  {
    place: 'Pipeline',
    city: 'Sundsvall',
    link: 'https://www.facebook.com/events/381509314918978?locale=sv_SE',
    bands: [],
    date: '2024-10-26',
  },
  {
    place: 'Alternativfesten',
    city: 'Sandviken',
    link: 'https://www.facebook.com/events/533413755733548?locale=sv_SE',
    bands: [],
    date: '2024-11-08',
  },
];
