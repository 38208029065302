import styled from 'styled-components';

const MainColor = 'rgba(255,255,255,.7)';

export const H1 = styled.h1`
  font-size: 15vw;
  font-weight: 900;
  text-align: center;
  color: ${MainColor};
  line-height: 8vw;
`;
export const H2 = styled.h2`
  text-align: center;
  font-size: 3rem;
  font-weight: 900;
  color: ${MainColor};
`;
export const H3 = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  color: ${MainColor};
`;
export const P = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${MainColor};
`;
export const Link = styled.a`
  font-size: 2rem;
  font-weight: 800;
  padding: 1rem;
  transition: 500ms;
  text-decoration: none;
  color: ${MainColor};
  &:link {
    color: ${MainColor};
  }
  &:visited {
    color: ${MainColor};
  }
  &:hover {
    color: ${MainColor};
  }
  &:active {
    color: ${MainColor};
  }
  &:hover {
    text-shadow: 0 0 20px gold;
    cursor: pointer;
  }
`;
