import styled from 'styled-components';

export const Header = styled.header`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('./img/background.jpg') no-repeat;
  background-position: center;
  background-size: cover;
`;
